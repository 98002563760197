import React from "react";
import ipfLogo from "../asset/ipfLogo.webp";
import ipfplLogo from "../asset/ipfplLogo.webp";
import sifmLogo from "../asset/sifmLogo.webp";
import { useNavigate } from "react-router-dom";

export default function Home(props) {
  const navigate = useNavigate();

  const loginElements = [
    {
      id: "ipfFmsLogin",
      name: "IPF FMS",
      src: ipfLogo,
    },
    {
      id: "ipfLogin",
      name: "IPF",
      src: ipfplLogo,
    },
    {
      id: "sifmLogin",
      name: "SIFM",
      src: sifmLogo,
    },
  ];
// const handler=(data)=>{
//   sessionStorage.setItem("logo", data?.src);
// }
  return (
    <div className="" style={{ height: "100%" }}>
      <div
        className=""
        style={{
          "text-align": "center",
          "font-size": "24px",
          "font-weight": "500",
          padding: "24px",
        }}
      >
        Select your Company
      </div>

      <div
        style={{
          width: "100%",
          height: "calc(100% - 84px)",
          display: "flex",
          "justify-content": "center",
          "flex-direction": "column",
          "align-items": "center",
        }}
      >
        <div
          className=" "
          style={{
            maxWidth: "800px",
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            "flex-wrap": "wrap",
          }}
        >
          {loginElements.map((data) => (
            <div
              onClick={() => {
                props.setLogin(data.src);
                sessionStorage.setItem("logo", data?.src);
                console.log('logo',data?.src)
                sessionStorage.setItem("companyname", data?.name);
                navigate("/login");
                // logo={data.src}
                console.log("data.src", data.src);
              }}
              style={{ padding: "0px 18px 18px 18px" }}
            >
              <img
                src={data.src}
                alt={data.id}
                style={{
                  width: "150px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />

              <button
                style={{ marginTop: "22px" }}
                className="logo_login"
                // data={data.src}
                >
                {data.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
